"use client";
import React, { useState } from "react";
import { CONTACT_EMAIL } from "@/constants/landing";
import { MailSend } from "@/components/icons/mingcute";
import { Button } from "@/components/ui/button";

const CopyEmailButtonSVG = ({ id }: { id: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(): Promise<void> {
    return navigator.clipboard.writeText(CONTACT_EMAIL);
  }

  const handleCopyClick = (): void => {
    copyTextToClipboard()
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Button size="icon" variant="ghost" className="h-6 w-6" id={id} onClick={handleCopyClick}>
      <MailSend className="h-6 w-6 fill-text-link-active-onBg data-[copied=true]:fill-primitive-purple-pale-800" data-copied={isCopied} />
    </Button>
  );
};
export default CopyEmailButtonSVG;
